import React from 'react';
import {FaLinkedin} from "@react-icons/all-files/fa/FaLinkedin";
import {FaGithub} from "@react-icons/all-files/fa/FaGithub";
import {FaSpotify} from "@react-icons/all-files/fa/FaSpotify";
import {MdPictureAsPdf} from "@react-icons/all-files/md/MdPictureAsPdf";
import {GrInstagram} from "@react-icons/all-files/gr/GrInstagram";

export default function About() {
    return (
        <div className="experienceOutput">
          <hr class="test"/>
          <h2 className="experienceHeader">education</h2>
          <hr/>
          <div class="divOneE">
          <img src="usc-logo.png" alt="" class="uscLogo"></img>
          </div>
          <div class="divTwoE">
            <h5 class="experienceTitle">
                University of Southern California — Viterbi School of Engineering
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">Bachelor of Science & Master of Science</span>,
                <span class="experienceSubtitleBold"> Computer Science</span>
            </h5>
            <h5 class="experienceSubtitle">
                Minor, <span class="experienceSubtitleBold">Applied Analytics</span> | Expected Graduation: <span class="experienceSubtitleBold">May 2024</span>
            </h5>
            {/* <p/> */}
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">· Undergraduate Coursework:</span> Data Structures & Object Oriented Design (C++), Computer Systems (C), 
            Artificial Intelligence & Machine Learning (Python), Software Engineering (Java), Internetworking, Operating Systems, Embedded Systems
            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">· Graduate Coursework:</span> Analysis of Algorithms, Information Retrieval & Web Search Engines,
            Database Systems, Multimedia Systems Design
            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">· Activities:</span> Scope (President), Makers (General Member)
            </h5>
          </div>
          <hr class="test"/>
          <h2 className="experienceHeader">experience</h2>
          <hr class="testt"/>
          <div class="row">
          <div class="divOneExpLeft">
          <img src="Goldman_Sachs_Signature.png" alt="" class="GSLogo"></img>
          
          </div>
          <div class="divTwoExpLeft">
            <h5 class="experienceTitle">
                Goldman Sachs
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">Engineering Summer Analyst</span>
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">New York, NY</span>
            </h5>
            <h5 class="experienceSubtitle">
                June 2023 - August 2023            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">Data Design & Curation Team</span>
            </h5>
            {/* <h5/> */}
            <h5 class="experienceSubtitle">
                June 2022 - August 2022            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">Franchise Data Engineering Team</span>
            </h5>
            {/* <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">Global Markets Business Intelligence</span>
            </h5> */}
          </div>
          <div class="divTwoExpRight">
            <h5 class="experienceTitle">
                USC Center for AI in Society
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">Undergraduate Research Assistant</span>
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">Los Angeles, CA</span>
            </h5>
            <h5 class="experienceSubtitle">
                September 2021 - Present           </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">Machine Learning Researcher</span>
            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">· Substance Abuse Treatment Engagement</span>
            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">· Predicting Child Malnutrition in Kenya</span>
            </h5>
          </div>
          <div class="divOneExpRight">
          <img src="CAIS_logo.png" alt="" class="CAISLogo"></img>
          </div>
          <br/>
          <br/>
          <br/>
          <div class="roww">
          <div class="divOneExpLeftToo">
          <img src="Viterbi_Logo.png" alt="" class="ViterbiLogo"></img>
          </div>
          <div class="divTwoExpLeftToo">
            <h5 class="experienceTitle" font-size="20px">
                USC Viterbi School of Engineering
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">Computer Science Head Course Producer</span>
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">Los Angeles, CA</span>
            </h5>
            <h5 class="experienceSubtitle">
                August 2021 - Present            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">CSCI 103: Introduction to Programming in C++</span>
            </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">CSCI 104: Data Structures & Object Oriented Design</span>
            </h5>
          </div>
          <div class="divTwoExpRightToo">
            <h5 class="experienceTitle">
                Scope USC Organization
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">President</span>
            </h5>
            <h5 class="experienceSubtitle">
                <span class="experienceSubtitleWhite">Los Angeles, CA</span>
            </h5>
            <h5 class="experienceSubtitle">
                May 2022 - Present           </h5>
            <h5 class="experienceSubtitle">
            <span class="experienceSubtitleBold">· React, NodeJS Curriculum Leader</span>
            </h5>
          </div>
          <div class="divOneExpRightToo">
          <img src="full-logo-yellow-sash.png" alt="" class="ScopeLogo"></img>
          </div>
          </div>
          </div>
          <div className="foot">
        <a class="linkedinn" href="https://linkedin.com/in/paulsomodi">
        <FaLinkedin className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://github.com/unpaul">
        <FaGithub className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://instagram.com/paulsomodi">
        <GrInstagram className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://open.spotify.com/user/lg2cgvmbhlj2ygtn4wpmmlg3r?si=80907cdb39244233">
        <FaSpotify className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://drive.google.com/file/d/1NzpLqK9K3leuQK4G8zIQSYENDCRtQPpj/view?usp=sharing">
        <MdPictureAsPdf className="linkedin"/>
        </a>
    </div>
    </div>
    
    );
  }