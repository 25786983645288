import React from 'react';
import {FaLinkedin} from "@react-icons/all-files/fa/FaLinkedin";
import {FaGithub} from "@react-icons/all-files/fa/FaGithub";
import {FaSpotify} from "@react-icons/all-files/fa/FaSpotify";
import {MdPictureAsPdf} from "@react-icons/all-files/md/MdPictureAsPdf";
import {GrInstagram} from "@react-icons/all-files/gr/GrInstagram";

export default function Projects() {
    return (
        <div className="experienceOutput">
          <hr class="test"/>
          <h2 className="experienceHeader">projects</h2>
          <hr class="testt"/>
          <div class="divTwoE">
            <h5 class="experienceTitle">
                Time-a-Scope – 2023 HackSC Hackathon Winner</h5>
            <h5 class="experienceSubtitleWhite">
                React Native, Python, Flask, CSS
            </h5>
            <h5 class="experienceSubtitleBold">
                Spring 2023
            </h5>
            <h5 class="experienceSubtitle">
            • Developed a React Native application that allows users to visualize the time-based locations of planets and stars
            </h5><h5 class="experienceSubtitle">
• Constructed a RESTful API using Flask and Python that returns the coordinates of a given planet at a given time
</h5><h5 class="experienceSubtitle">
• Placed 1st out of 11 teams within the ”Beyond the Globe” vertical at HackSC 2023, focusing on space exploration
            </h5>
          </div>
          {/* <hr class="testt"/> */}
          {/* <div class="divTwoE">
            <h5 class="experienceTitle">
                EventMe            </h5>
            <h5 class="experienceSubtitleWhite">
                Java, CSS
            </h5>
            <h5 class="experienceSubtitleBold">
                Fall 2022
            </h5>
            <h5 class="experienceSubtitle">
            · ??
            </h5>
          </div> */}
          {/* <hr class="testt"/>
          <div class="divTwoE">
            <h5 class="experienceTitle">
                Personal Website            </h5>
            <h5 class="experienceSubtitleWhite">
                ReactJS, CSS
            </h5>
            <h5 class="experienceSubtitleBold">
                Summer 2022
            </h5>
            <h5 class="experienceSubtitle">
            · Utilized ReactJS framework and CSS styling to create a dynamic web application that showcases personal portfolio
            </h5>
          </div> */}
          <hr class="test"/>
          <div class="divTwoE">
            <h5 class="experienceTitle">
                ProgressBar - Job Application Status Tracker            </h5>
            <h5 class="experienceSubtitleWhite">
                HTML/CSS, Java, JavaScript, MySQL
            </h5>
            <h5 class="experienceSubtitleBold">
                Spring 2022
            </h5>
            <h5 class="experienceSubtitle">
            · Developed a multi-threaded web application that allows users to track the status of active job applications            </h5>
            <h5 class="experienceSubtitle">
            · Implemented JSP and web servlets to process and pass information between database and frontend interface            </h5>
            <h5 class="experienceSubtitle">
            · Designed database and complex SQL queries for proper data storage and retrieval hosted on AWS platform            </h5>
          </div>
          <hr class="test"/>
          <div class="divTwoE">
            <h5 class="experienceTitle">
                Reverse Choreography            </h5>
            <h5 class="experienceSubtitleWhite">
                Python, NumPy, Pandas, Spotify API, Scikit-Learn, OpenCV
            </h5>
            <h5 class="experienceSubtitleBold">
                Spring 2022
            </h5>
            <h5 class="experienceSubtitle">
            · Created a machine learning model that recommends a Spotify playlist of songs based on dance moves            </h5>
            <h5 class="experienceSubtitle">
            · Leveraged Spotify API to extract song audio features for playlist generation            </h5>
            <h5 class="experienceSubtitle">
            · Built a support vector machine that matches movement data to a song with a classification accuracy of 97%            </h5>
          </div>
          <hr class="test"/>
          <div className="foot">
        <a class="linkedinn" href="https://linkedin.com/in/paulsomodi">
        <FaLinkedin className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://github.com/unpaul">
        <FaGithub className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://instagram.com/paulsomodi">
        <GrInstagram className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://open.spotify.com/user/lg2cgvmbhlj2ygtn4wpmmlg3r?si=80907cdb39244233">
        <FaSpotify className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://drive.google.com/file/d/1NzpLqK9K3leuQK4G8zIQSYENDCRtQPpj/view?usp=sharing">
        <MdPictureAsPdf className="linkedin"/>
        </a>
    </div>
    </div>
    
    );
  }
  