import Typewriter from 'typewriter-effect';
import React from 'react';
import {FaLinkedin} from "@react-icons/all-files/fa/FaLinkedin";
import {FaGithub} from "@react-icons/all-files/fa/FaGithub";
import {FaSpotify} from "@react-icons/all-files/fa/FaSpotify";
import {MdPictureAsPdf} from "@react-icons/all-files/md/MdPictureAsPdf";
import {GrInstagram} from "@react-icons/all-files/gr/GrInstagram";
export default function Splash() {
    return (
        <div className="bodyOutput">
          <script src="https://use.fontawesome.com/19340e200c.js"></script>
        <span className="bodyTitle">Paul Somodi</span>
        <h2 className="bodySubtitle">
        <Typewriter
      options={{loop: true, deleteSpeed: 40, delay: 40}}
      onInit={(typewriter) => {typewriter
        .typeString('software engineer')
        .pauseFor(1000)
        .deleteAll()
        .typeString('coffee enthusiast')
        .pauseFor(1000)
        .deleteAll()
        .typeString('data scientist')
        .pauseFor(1000)
        .deleteAll()
        .typeString('extreme couponer')
        .pauseFor(1000)
        .deleteAll()
        .typeString('gamer')
        .pauseFor(1000)
        .deleteAll()
        .typeString('web developer')
        .pauseFor(1000)
        .deleteAll()
        .start();}}
      />
        </h2>
        <div className="foot">
        <a class="linkedinn" href="https://linkedin.com/in/paulsomodi">
        <FaLinkedin className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://github.com/unpaul">
        <FaGithub className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://instagram.com/paulsomodi">
        <GrInstagram className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://open.spotify.com/user/lg2cgvmbhlj2ygtn4wpmmlg3r?si=80907cdb39244233">
        <FaSpotify className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://drive.google.com/file/d/1NzpLqK9K3leuQK4G8zIQSYENDCRtQPpj/view?usp=sharing">
        <MdPictureAsPdf className="linkedin"/>
        </a>
    </div>
    </div>
    );
  }