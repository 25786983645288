import React from 'react';
export default function Navbar() {
  const isActive = window.location.pathname;
  return (
    <nav className='navbar sticky'>
      <div className='nav-center'>
        <div class="header-name">
      {isActive === "/" ? <img src="new-logo.png" class="imgggg" alt=""/> :
      
      <img src="new-logo.png" class="imgggg" alt=""/>
      // <Typewriter
      // options={{loop: false}}
      // onInit={(typewriter) => {typewriter
      //   .typeString('paul somodi')
      //   .start();}}
      // />
    //  <img src="chibi.png" class="imgg" alt=""/>
      
       }
      </div>
        <div>
        <a href="/" className={isActive==="/" ? 'active' : 'inactive'}>hub</a>
        <a href="bio" className={isActive==="/bio" ? 'active' : 'inactive'}>biography</a>
        <a href="experience" className={isActive==="/experience" ? 'active' : 'inactive'}>experience</a>
        <a href="projects" className={isActive==="/projects" ? 'active' : 'inactive'}>projects</a>
        </div>
      </div>
    </nav>
  )
}