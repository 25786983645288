import React from 'react';
import {FaLinkedin} from "@react-icons/all-files/fa/FaLinkedin";
import {FaGithub} from "@react-icons/all-files/fa/FaGithub";
import {FaSpotify} from "@react-icons/all-files/fa/FaSpotify";
import {MdPictureAsPdf} from "@react-icons/all-files/md/MdPictureAsPdf";
import {GrInstagram} from "@react-icons/all-files/gr/GrInstagram";

export default function About() {
    return (
        <div className="aboutOutput">
          <div class="divOne">
          <img src="headshot_square.jpeg" alt="" class="headshot"></img>
          </div>
          <div class="divTwo">
          <h2> 
            <span class = "bioHeader">hello, my name is </span>
            <span class = "bioHeaderTwo">paul</span>
            <span class = "bioHeader">...</span>
          </h2>
          <paragraph class="bioText">
            i'm a <span class = "bioTextTwo">computer science</span> student at  <span class = "bioTextThree">USC</span> and i'm passionate about 
            using engineering to make the world a better place. at <span class = "bioTextThree">USC</span>, i'm
            involved in undergraduate research focusing on using AI for social good. i'm 
            also involved as a <span class = "bioTextTwo">teaching assistant</span> for the intro to <span class = "bioTextTwo">C++</span> course at <span class = "bioTextThree">USC</span>.
            <br/><br/>my areas of expertise include: <br/><span class = "bioTextTwo">machine learning</span>, 
            <span class = "bioTextTwo"> software engineering</span>, & 
            <span class = "bioTextTwo"> data science</span>.<br/><br/>
            outside of coding, i enjoy listening to <span class = "bioTextTwo">music</span>, 
            trying new <span class = "bioTextTwo">restaurants</span>,
            finding a good <span class = "bioTextTwo">discount</span>, 
            and drinking <span class = "bioTextTwo">coffee</span>.
            </paragraph>
            <h2> 
            <span class = "bioHeader">... it's nice to meet </span>
            <span class = "bioHeaderTwo">you</span>
            <span class = "bioHeader">.</span>
          </h2>
          </div>
          <div className="foot">
        <a class="linkedinn" href="https://linkedin.com/in/paulsomodi">
        <FaLinkedin className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://github.com/unpaul">
        <FaGithub className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://instagram.com/paulsomodi">
        <GrInstagram className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://open.spotify.com/user/lg2cgvmbhlj2ygtn4wpmmlg3r?si=80907cdb39244233">
        <FaSpotify className="linkedin"/>
        </a>
        <a class="linkedinn" href="https://drive.google.com/file/d/1NzpLqK9K3leuQK4G8zIQSYENDCRtQPpj/view?usp=sharing">
        <MdPictureAsPdf className="linkedin"/>
        </a>
    </div>
    </div>
    );
  }