import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/styles/globals.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar.js';
import Splash from './components/Splash.js';
import About from './components/About.js';
import Experience from './components/Experience.js';
import Projects from './components/Projects.js';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="title">
    <Navbar/>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Splash/>}/>
      <Route path="/bio" element={<About/>}/>
      <Route path="/experience" element={<Experience/>}/>
      <Route path="/projects" element={<Projects/>}/>
      </Routes>
  </BrowserRouter>
  </div>
);
